import React from 'react';
import { Box, FormControl, MenuItem, Select, TextField, Typography, InputLabel } from '@mui/material';

const getYears = (startYear) => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = startYear; year <= currentYear; year++) {
        years.push(year);
    }
    return years;
};

const years = getYears(2018);

const Sidebar = ({ searchQuery, setSearchQuery, selectedLanguage, setSelectedLanguage, selectedStatus, setSelectedStatus, selectedYear, setSelectedYear }) => {
    const handleLanguageChange = (event) => {
        setSelectedLanguage(event.target.value);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleStatusChange = (event) => {
        setSelectedStatus(event.target.value);
    };

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    return (
        <Box sx={{ padding: 2 }}>
            <Typography variant="h6" gutterBottom>
                Search
            </Typography>
            <TextField
                label="Search"
                value={searchQuery}
                onChange={handleSearchChange}
                fullWidth
                sx={{ marginBottom: 2 }}
            />
            <Typography variant="h6" gutterBottom>
                Language
            </Typography>
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <Select value={selectedLanguage} onChange={handleLanguageChange}>
                    <MenuItem value="en">English</MenuItem>
                    <MenuItem value="fr">French</MenuItem>
                    <MenuItem value="rw">Kinyarwanda</MenuItem>
                </Select>
            </FormControl>
            <Typography variant="h6" gutterBottom>
                Status
            </Typography>
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <Select value={selectedStatus} onChange={handleStatusChange}>
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Repealed">Repealed</MenuItem>
                    <MenuItem value="Replaced/Amended">Replaced/Amended</MenuItem>
                </Select>
            </FormControl>
            <Typography variant="h6" gutterBottom>
                Year
            </Typography>
            <FormControl fullWidth>
                <Select value={selectedYear} onChange={handleYearChange}>
                    <MenuItem value="">
                        <em>All Years</em>
                    </MenuItem>
                    {years.map(year => (
                        <MenuItem key={year} value={year}>
                            {year}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export default Sidebar;
