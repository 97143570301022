import { createTheme } from "@mui/material";

export const primary_main = "#004D4D";
export const primary_light = "#066b6b";
export const primary_dark = "#013333";
export const secondary_main = "#D65D2B";
export const yellow = "#E99F22";
export const red = "#ff3333";

const light_theme = createTheme({
    palette: {
        primary: {
            main: primary_main,
            light: primary_light,
            dark: primary_dark,
            red: red,
        },
        secondary: {
            main: secondary_main,
            light: "#ffe24b",
            dark: "#c28100",
        },
        whitecolors: {
            main: "#fff",
            light: "#ffe24b",
            dark: "#eeeeee",
            input_light: "#D9D9D9",
            input_dark: "#c5c5c5",
        },
        blackcolors: {
            main: "black",
            light: "#656565",
        },
    },
    typography: {
        fontFamily: "Arial, josefin-sans",
        h2: {
            fontSize: "15px",
            color: primary_main,
            fontWeight: "bold",
            marginTop: "5px",
            marginBottom: "3px",
        },
        h3: {
            fontSize: "14px",
            color: secondary_main,
            fontWeight: "bold",
            marginTop: "2px",
        },
        h4: {
            fontSize: "12px",
            color: secondary_main,
            fontWeight: "bold",
            marginTop: "2px",
        },
        h5: {
            fontSize: "16px",
            fontWeight: "bold",
            marginTop: "5px",
        },
        error: {
            fontFamily: "Arial, josefin-sans",
            fontSize: "14px",
            color: red,
            fontWeight: "light",
        },
    },
});

export default light_theme;