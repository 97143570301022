import React, { useState } from 'react';
import { Button, Box, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, Alert as MuiAlert } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const DeleteAccount = ({ api }) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const navigate = useNavigate();

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleDeleteAccount = () => {
        const token = localStorage.getItem('access_token');

        fetch(`${api}/user/delete_account`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to delete account');
                }
                return response.json();
            })
            .then(data => {
                setSnackbarMessage('Account deleted successfully!');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                localStorage.removeItem('access_token');
                navigate('/login');
            })
            .catch(error => {
                setSnackbarMessage('Failed to delete account.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            })
            .finally(() => {
                setDialogOpen(false);
            });
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <Box>
            <Typography variant="h5" gutterBottom>
                Delete Account
            </Typography>
            <Button variant="contained" color="secondary" onClick={handleDialogOpen}>
                Delete Account
            </Button>
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Confirm Account Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete your account? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteAccount} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </Box>
    );
};

export default DeleteAccount;
