import React, { useState } from 'react';
import { Container, TextField, Button, Snackbar, Typography, Box } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/system';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledForm = styled('form')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
}));

const AddReference = ({ api }) => {
    const [description, setDescription] = useState('');
    const [file, setFile] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!file) {
            setSnackbarMessage('Please select a file.');
            setSnackbarSeverity('warning');
            setSnackbarOpen(true);
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('description', description);

        try {
            const response = await fetch(`${api}/reference/add`, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                setSnackbarMessage('Reference added successfully!');
                setSnackbarSeverity('success');
            } else {
                const data = await response.json();
                setSnackbarMessage(`Failed to add reference: ${data.detail}`);
                setSnackbarSeverity('error');
            }
        } catch (error) {
            setSnackbarMessage(`Error: ${error.message}`);
            setSnackbarSeverity('error');
        } finally {
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Add Reference
            </Typography>
            <StyledForm onSubmit={handleSubmit}>
                <TextField
                    label="Description"
                    value={description}
                    onChange={handleDescriptionChange}
                    fullWidth
                    margin="normal"
                />
                <Button
                    variant="contained"
                    component="label"
                >
                    Upload PDF
                    <input
                        type="file"
                        hidden
                        accept="application/pdf"
                        onChange={handleFileChange}
                    />
                </Button>
                {file && <Typography variant="body2">{file.name}</Typography>}
                <Box mt={2}>
                    <Button variant="contained" color="primary" type="submit">
                        Add Reference
                    </Button>
                </Box>
            </StyledForm>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default AddReference;
