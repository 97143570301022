import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as KaizenMindLogo } from '../assets/images/KaizenMind logo-03.svg';
function CustomIcon(props) {
    return (
        <SvgIcon {...props} style={{ fontSize: '3.1rem' }}>
            <KaizenMindLogo />
        </SvgIcon>
    );
}

export default CustomIcon;
