import React, { useState } from 'react';
import { Container, Box, CssBaseline } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CardNavigation from '../components/cardNavigation';

const Admin = ({ api }) => {
    const [selectedComponent, setSelectedComponent] = useState(null);
    const navigate = useNavigate();
    const handleCardClick = (component) => {
        setSelectedComponent(component);
    };

    const renderComponent = () => {
        switch (selectedComponent) {
            case 'add':
                return navigate("/museke/addchapter");
            case 'update-delete':
                return navigate("/museke/updatechapters");
            case 'add-reference':
                return navigate("/museke/addreference");
            case 'update-reference':
                return navigate("/museke/updatereference");
            case 'manage-quotes':
                return navigate("/museke/managequotes");
            default:
                return <CardNavigation onCardClick={handleCardClick} />;
        }
    };

    return (
        <Container>
            <CssBaseline />
            <Box sx={{ mt: 4 }}>
                {renderComponent()}
            </Box>
        </Container>
    );
};

export default Admin;
