import React from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';

const CardNavigation = ({ onCardClick }) => {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                    <Card onClick={() => onCardClick('add')}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Add Chapter
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Add a new chapter with sections and articles.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card onClick={() => onCardClick('update-delete')}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Update/Delete Chapter
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Update or delete existing chapters.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card onClick={() => onCardClick('add-reference')}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Add reference
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Add a new reference with its pdf file
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card onClick={() => onCardClick('update-reference')}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Update reference
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                update an existing refence
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card onClick={() => onCardClick('manage-quotes')}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Manage Quotes
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Add/edit or delete and choose which quotes are shown
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CardNavigation;
