import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, Typography, Box, Grid, Paper, MenuItem, Select, Snackbar, Alert as MuiAlert, FormControl, InputLabel, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DateTime } from 'luxon';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AddChapter = ({ api }) => {
    const [chapterName, setChapterName] = useState('');
    const languageOptions = ["rw", "fr", "en"];
    const statusOptions = ["Active", "Repealed", "Replaced/Amended"];
    const [references, setReferences] = useState([]);
    const [translations, setTranslations] = useState([{ language_code: '', text: '', desc: '' }]);
    const [sections, setSections] = useState([
        {
            name: '',
            translations: [{ language_code: '', text: '', desc: '' }],
            articles_lst: [
                {
                    title: '',
                    effective_date: DateTime.now().toISODate(),
                    status: 'Active',
                    reference_id: '',
                    translations: [{ language_code: '', text: '', desc: '' }]
                }
            ]
        }
    ]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [missingTranslationsDialogOpen, setMissingTranslationsDialogOpen] = useState(false);
    const [missingTranslationsMessage, setMissingTranslationsMessage] = useState('');

    useEffect(() => {
        const fetchReferences = async () => {
            try {
                const response = await fetch(`${api}/references`);
                const data = await response.json();
                setReferences(data);
            } catch (error) {
                setSnackbarMessage(`Error fetching references: ${error.message}`);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            }
        };

        fetchReferences();
    }, [api]);

    const handleChapterNameChange = (e) => setChapterName(e.target.value);

    const handleTranslationChange = (index, key, value) => {
        const newTranslations = [...translations];
        newTranslations[index][key] = value;
        setTranslations(newTranslations);
    };

    const addTranslation = () => {
        setTranslations([...translations, { language_code: '', text: '', desc: '' }]);
    };

    const handleSectionChange = (sectionIndex, key, value) => {
        const newSections = [...sections];
        newSections[sectionIndex][key] = value;
        setSections(newSections);
    };

    const handleSectionTranslationChange = (sectionIndex, index, key, value) => {
        const newSections = [...sections];
        newSections[sectionIndex].translations[index][key] = value;
        setSections(newSections);
    };

    const addSectionTranslation = (sectionIndex) => {
        const newSections = [...sections];
        newSections[sectionIndex].translations.push({ language_code: '', text: '', desc: '' });
        setSections(newSections);
    };

    const handleArticleChange = (sectionIndex, articleIndex, key, value) => {
        const newSections = [...sections];
        newSections[sectionIndex].articles_lst[articleIndex][key] = value;
        setSections(newSections);
    };

    const handleArticleDateChange = (sectionIndex, articleIndex, date) => {
        const newSections = [...sections];
        newSections[sectionIndex].articles_lst[articleIndex].effective_date = DateTime.fromJSDate(date).toISODate();
        setSections(newSections);
    };

    const handleArticleTranslationChange = (sectionIndex, articleIndex, index, key, value) => {
        const newSections = [...sections];
        newSections[sectionIndex].articles_lst[articleIndex].translations[index][key] = value;
        setSections(newSections);
    };

    const addArticleTranslation = (sectionIndex, articleIndex) => {
        const newSections = [...sections];
        newSections[sectionIndex].articles_lst[articleIndex].translations.push({ language_code: '', text: '', desc: '' });
        setSections(newSections);
    };

    const addSection = () => {
        setSections([...sections, {
            name: '',
            translations: [{ language_code: '', text: '', desc: '' }],
            articles_lst: [
                {
                    title: '',
                    effective_date: DateTime.now().toISODate(),
                    status: 'Active',
                    reference_id: '',
                    translations: [{ language_code: '', text: '', desc: '' }]
                }
            ]
        }]);
    };

    const addArticle = (sectionIndex) => {
        const newSections = [...sections];
        newSections[sectionIndex].articles_lst.push({
            title: '',
            effective_date: DateTime.now().toISODate(),
            status: 'Active',
            reference_id: '',
            translations: [{ language_code: '', text: '', desc: '' }]
        });
        setSections(newSections);
    };

    const checkMissingTranslations = () => {
        let missingTranslations = [];

        // Check chapter translations
        languageOptions.forEach(language => {
            if (!translations.some(t => t.language_code === language)) {
                missingTranslations.push(`Chapter is missing ${language} translation.`);
            }
        });

        // Check section translations
        sections.forEach((section, sectionIndex) => {
            languageOptions.forEach(language => {
                if (!section.translations.some(t => t.language_code === language)) {
                    missingTranslations.push(`Section ${sectionIndex + 1} is missing ${language} translation.`);
                }
            });

            // Check article translations
            section.articles_lst.forEach((article, articleIndex) => {
                languageOptions.forEach(language => {
                    if (!article.translations.some(t => t.language_code === language)) {
                        missingTranslations.push(`Article ${articleIndex + 1} in section ${sectionIndex + 1} is missing ${language} translation.`);
                    }
                });
            });
        });

        return missingTranslations;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const missingTranslations = checkMissingTranslations();

        if (missingTranslations.length > 0) {
            setMissingTranslationsMessage(missingTranslations.join('\n'));
            setMissingTranslationsDialogOpen(true);
            return;
        }

        const data = {
            name: chapterName,
            translations,
            sections_lst: sections,
        };

        fetch(`${api}/chapter/add`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => {
                setSnackbarMessage('Chapter added successfully!');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                setChapterName('');
                setTranslations([{ language_code: '', text: '', desc: '' }]);
                setSections([
                    {
                        name: '',
                        translations: [{ language_code: '', text: '', desc: '' }],
                        articles_lst: [
                            {
                                title: '',
                                effective_date: DateTime.now().toISODate(),
                                status: 'Active',
                                reference_id: '',
                                translations: [{ language_code: '', text: '', desc: '' }]
                            }
                        ]
                    }
                ]);
            })
            .catch(error => {
                setSnackbarMessage('Failed to add chapter.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            });
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <Container sx={{ margin: "10px" }}>
            <Typography variant="h4" gutterBottom>
                Add New Chapter
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Chapter Name"
                    value={chapterName}
                    onChange={handleChapterNameChange}
                    fullWidth
                    margin="normal"
                />
                <Box mt={3}>
                    <Typography variant="h6">Translations</Typography>
                    {translations.map((translation, index) => (
                        <Grid container spacing={2} key={index}>
                            <Grid item xs={4}>
                                <Select
                                    label="Language Code"
                                    value={translation.language_code}
                                    onChange={(e) => handleTranslationChange(index, 'language_code', e.target.value)}
                                    fullWidth
                                >
                                    {languageOptions.map((option) => (
                                        <MenuItem key={option} value={option}>{option}</MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="Chapter Name"
                                    value={translation.text}
                                    onChange={(e) => handleTranslationChange(index, 'text', e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    ))}
                    <Button onClick={addTranslation}>Add Translation</Button>
                </Box>
                <Box mt={3}>
                    <Typography variant="h6">Sections</Typography>
                    {sections.map((section, sectionIndex) => (
                        <Paper key={sectionIndex} variant="outlined" sx={{ mb: 2, p: 2 }}>
                            <TextField
                                label="Section Name"
                                value={section.name}
                                onChange={(e) => handleSectionChange(sectionIndex, 'name', e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                            <Typography variant="h6">Section Translations</Typography>
                            {section.translations.map((translation, index) => (
                                <Grid container spacing={2} key={index}>
                                    <Grid item xs={4}>
                                        <Select
                                            label="Language Code"
                                            value={translation.language_code}
                                            onChange={(e) => handleSectionTranslationChange(sectionIndex, index, 'language_code', e.target.value)}
                                            fullWidth
                                        >
                                            {languageOptions.map((option) => (
                                                <MenuItem key={option} value={option}>{option}</MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            label="Section Name"
                                            value={translation.text}
                                            onChange={(e) => handleSectionTranslationChange(sectionIndex, index, 'text', e.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            ))}
                            <Button onClick={() => addSectionTranslation(sectionIndex)}>Add Section Translation</Button>
                            <Typography variant="h6">Articles</Typography>
                            {section.articles_lst.map((article, articleIndex) => (
                                <Paper key={articleIndex} variant="outlined" sx={{ mb: 2, p: 2 }}>
                                    <TextField
                                        label="Article Title"
                                        value={article.title}
                                        onChange={(e) => handleArticleChange(sectionIndex, articleIndex, 'title', e.target.value)}
                                        fullWidth
                                        margin="normal"
                                    />
                                    <DatePicker
                                        selected={DateTime.fromISO(article.effective_date).toJSDate()}
                                        onChange={(date) => handleArticleDateChange(sectionIndex, articleIndex, date)}
                                        dateFormat="MMMM d, yyyy"
                                        customInput={
                                            <TextField
                                                label="Effective Date"
                                                fullWidth
                                                margin="normal"
                                            />
                                        }
                                        required
                                    />
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel>Status</InputLabel>
                                        <Select
                                            value={article.status}
                                            onChange={(e) => handleArticleChange(sectionIndex, articleIndex, 'status', e.target.value)}
                                        >
                                            {statusOptions.map((option) => (
                                                <MenuItem key={option} value={option}>{option}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel>Reference</InputLabel>
                                        <Select
                                            value={article.reference_id}
                                            onChange={(e) => handleArticleChange(sectionIndex, articleIndex, 'reference_id', e.target.value)}
                                            required
                                        >
                                            {references.map((reference) => (
                                                <MenuItem key={reference.id} value={reference.id}>
                                                    {reference.description}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Typography variant="h6">Article Translations</Typography>
                                    {article.translations.map((translation, index) => (
                                        <Grid container spacing={2} key={index}>
                                            <Grid item xs={4}>
                                                <Select
                                                    label="Language Code"
                                                    value={translation.language_code}
                                                    onChange={(e) => handleArticleTranslationChange(sectionIndex, articleIndex, index, 'language_code', e.target.value)}
                                                    fullWidth
                                                >
                                                    {languageOptions.map((option) => (
                                                        <MenuItem key={option} value={option}>{option}</MenuItem>
                                                    ))}
                                                </Select>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    label="Article Title"
                                                    value={translation.text}
                                                    onChange={(e) => handleArticleTranslationChange(sectionIndex, articleIndex, index, 'text', e.target.value)}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    label="Article Description"
                                                    multiline
                                                    value={translation.desc}
                                                    onChange={(e) => handleArticleTranslationChange(sectionIndex, articleIndex, index, 'desc', e.target.value)}
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    ))}
                                    <Button onClick={() => addArticleTranslation(sectionIndex, articleIndex)}>Add Article Translation</Button>
                                </Paper>
                            ))}
                            <Button onClick={() => addArticle(sectionIndex)}>Add Article</Button>
                        </Paper>
                    ))}
                    <Button onClick={addSection}>Add Section</Button>
                </Box>
                <Box mt={3}>
                    <Button type="submit" variant="contained" color="primary">Submit</Button>
                </Box>
            </form>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            <Dialog
                open={missingTranslationsDialogOpen}
                onClose={() => setMissingTranslationsDialogOpen(false)}
                aria-labelledby="missing-translations-dialog-title"
                aria-describedby="missing-translations-dialog-description"
            >
                <DialogTitle id="missing-translations-dialog-title">Missing Translations</DialogTitle>
                <DialogContent>
                    <DialogContentText id="missing-translations-dialog-description">
                        {missingTranslationsMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setMissingTranslationsDialogOpen(false)} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default AddChapter;
