import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Typography, Snackbar, Select, MenuItem, FormControl, InputLabel, Alert as MuiAlert } from '@mui/material';
import { AuthContext } from '../contexts/AuthContext';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const RegisterIndividual = ({ api }) => {
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
    const { login, setUserType, setUserName } = useContext(AuthContext);
    const [snackbarState, setSnackbarState] = useState({ open: false, message: '', severity: 'success' });
    const [position, setPosition] = useState('');
    const [customPosition, setCustomPosition] = useState('');
    const navigate = useNavigate();

    const onSubmit = (data) => {
        const { confirmPassword, position, ...userData } = data;
        const finalPosition = position === 'Other' ? customPosition : position;
        const finalUserData = { ...userData, position: finalPosition };
        loginUser(finalUserData);
        fetch(`${api}/register/individual`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(finalUserData)
        })
            .then(response => response.ok ? response.json() : response.json().then(err => { throw err }))
            .then(() => {
                setSnackbarState({ open: true, message: 'Individual user registered successfully!', severity: 'success' });
                loginUser(finalUserData);
            })
            .catch(error => {
                setSnackbarState({ open: true, message: error.detail || 'Failed to register individual user.', severity: 'error' });
            });
    };

    const loginUser = (userData) => {
        const formData = new URLSearchParams();
        formData.append('username', userData.email);
        formData.append('password', userData.password);
        fetch(`${api}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: formData
        })
            .then(response => response.ok ? response.json() : response.json().then(err => { throw err }))
            .then(data => {
                login(data.access_token);
                setUserType(data.user_type);
                setUserName(data.user_name);
                setSnackbarState({ open: true, message: 'Login succeeded!', severity: 'success' });
                navigate('/');
            })
            .catch(() => {
                setSnackbarState({ open: true, message: 'Registration succeeded, but login failed.', severity: 'error' });
            });
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarState(prevState => ({ ...prevState, open: false }));
    };

    const password = watch("password");

    return (
        <Container sx={{ paddingTop: "10px" }}>
            <Typography variant="h4" gutterBottom>
                Register Individual User
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                    label="Username"
                    {...register('user_name', { required: true })}
                    fullWidth
                    margin="normal"
                    error={!!errors.user_name}
                    helperText={errors.user_name ? 'Username is required' : ''}
                />
                <TextField
                    label="Email"
                    {...register('email', { required: true })}
                    fullWidth
                    margin="normal"
                    error={!!errors.email}
                    helperText={errors.email ? 'Email is required' : ''}
                />
                <TextField
                    label="Phone"
                    {...register('phone')}
                    fullWidth
                    margin="normal"
                />
                <FormControl fullWidth margin="normal" error={!!errors.position}>
                    <InputLabel>Position</InputLabel>
                    <Select
                        label="Position"
                        value={position}
                        {...register('position', { required: true })}
                        onChange={(e) => setPosition(e.target.value)}
                    >
                        <MenuItem value="Intern/Trainee">Intern/Trainee</MenuItem>
                        <MenuItem value="Entry-Level">Entry-Level</MenuItem>
                        <MenuItem value="Junior">Junior</MenuItem>
                        <MenuItem value="Mid-Level">Mid-Level</MenuItem>
                        <MenuItem value="Senior">Senior</MenuItem>
                        <MenuItem value="Manager">Manager</MenuItem>
                        <MenuItem value="Director">Director</MenuItem>
                        <MenuItem value="Vice President">Vice President</MenuItem>
                        <MenuItem value="C-Level Executive (CEO, CFO, COO, etc.)">C-Level Executive (CEO, CFO, COO, etc.)</MenuItem>
                        <MenuItem value="Business Owner/Entrepreneur">Business Owner/Entrepreneur</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                    </Select>
                </FormControl>
                {position === 'Other' && (
                    <TextField
                        label="Custom Position"
                        value={customPosition}
                        onChange={(e) => setCustomPosition(e.target.value)}
                        fullWidth
                        margin="normal"
                        error={!!errors.customPosition}
                        helperText={errors.customPosition ? 'Custom Position is required' : ''}
                    />
                )}
                <TextField
                    label="Employer"
                    {...register('employer')}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Password"
                    type="password"
                    {...register('password', { required: true, minLength: 8 })}
                    fullWidth
                    margin="normal"
                    error={!!errors.password}
                    helperText={errors.password ? 'Password must be at least 8 characters long' : ''}
                />
                <TextField
                    label="Confirm Password"
                    type="password"
                    {...register('confirmPassword', {
                        required: true,
                        validate: value => value === password || "Passwords do not match"
                    })}
                    fullWidth
                    margin="normal"
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword ? errors.confirmPassword.message : ''}
                />
                <Button type="submit" variant="contained" color="primary">
                    Register
                </Button>
            </form>
            <Snackbar open={snackbarState.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarState.severity} sx={{ width: '100%' }}>
                    {snackbarState.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default RegisterIndividual;
