import React from 'react';
import { Box, Typography, Button } from '@mui/material';


const SubscriptionStatus = () => {
    const user_type = localStorage.getItem('user_type');
    return (
        <Box>
            <Typography variant="h5" gutterBottom>
                Subscription Status
            </Typography>
            <Typography variant="body1">
                {/* Display subscription status here */}
                Your subscription status will be displayed here.
            </Typography>
            {user_type !== "organization_user" && (<Button variant="contained" color="primary" sx={{ marginTop: '10px' }}>
                Manage Subscription
            </Button>)}

        </Box>
    );
};

export default SubscriptionStatus;
