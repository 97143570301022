import { createTheme } from "@mui/material";

export const dark_primary_main = "#066b6b";
export const dark_primary_light = "#333333";
export const dark_primary_dark = "#000000";
export const dark_secondary_main = "#004D4D";
export const dark_yellow = "#ffb300";
export const dark_red = "#cf6679";

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: dark_primary_main,
            light: dark_primary_light,
            dark: dark_primary_dark,
            red: dark_red,
        },
        secondary: {
            main: dark_secondary_main,
            light: "#e4aaff",
            dark: "#880e4f",
        },
        whitecolors: {
            main: "#121212",
            light: "#333333",
            dark: "#000000",
            input_light: "#424242",
            input_dark: "#303030",
        },
        blackcolors: {
            main: "#fff",
            light: "#bdbdbd",
        },
    },
    typography: {
        fontFamily: "Arial, josefin-sans",
        h2: {
            fontSize: "15px",
            color: dark_primary_main,
            fontWeight: "bold",
            marginTop: "5px",
            marginBottom: "3px",
        },
        h3: {
            fontSize: "14px",
            color: dark_secondary_main,
            fontWeight: "bold",
            marginTop: "2px",
        },
        h4: {
            fontSize: "12px",
            color: "#d66638",
            fontWeight: "bold",
            marginTop: "2px",
        },
        h5: {
            fontSize: "16px",
            fontWeight: "bold",
            marginTop: "5px",
        },
        error: {
            fontFamily: "Arial, josefin-sans",
            fontSize: "14px",
            color: dark_red,
            fontWeight: "light",
        },
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-root': {
                        borderColor: 'rgba(255, 255, 255, 0.23)',
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: 'rgba(255, 255, 255, 0.23)',
                        },
                        '&:hover fieldset': {
                            borderColor: 'rgba(255, 255, 255, 0.87)',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: dark_secondary_main,
                        },
                    },
                    '& .MuiInputLabel-root': {
                        color: 'rgba(255, 255, 255, 0.7)',
                        '&.Mui-focused': {
                            color: dark_secondary_main,
                        },
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: 'rgba(255, 255, 255, 0.23)',
                        },
                        '&:hover fieldset': {
                            borderColor: 'rgba(255, 255, 255, 0.87)',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: dark_secondary_main,
                        },
                    },
                    '& .MuiInputLabel-root': {
                        color: 'rgba(255, 255, 255, 0.7)',
                        '&.Mui-focused': {
                            color: dark_secondary_main,
                        },
                    },
                },
            },
        },
    },
});

export default darkTheme;
