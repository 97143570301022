import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Container, TextField, Button, Typography, Snackbar, Select, MenuItem, FormControl, InputLabel, Alert as MuiAlert } from '@mui/material';
import RequireAuth from '../components/RequireAuth';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const RegisterOrganizationUser = ({ api }) => {
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [position, setPosition] = useState('');
    const [customPosition, setCustomPosition] = useState('');

    const positionOptions = [
        "Intern/Trainee",
        "Entry-Level",
        "Junior",
        "Mid-Level",
        "Senior",
        "Manager",
        "Director",
        "Vice President",
        "C-Level Executive (CEO, CFO, COO, etc.)",
        "Business Owner/Entrepreneur",
        "Other"
    ];

    const onSubmit = (data) => {
        const finalPosition = position === 'Other' ? customPosition : position;
        const finalData = { ...data, position: finalPosition };

        fetch(`${api}/register/organization_user`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            },
            body: JSON.stringify(finalData)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(errorData => {
                        throw new Error(errorData.detail || 'Failed to register organization user.');
                    });
                }
                return response.json();
            })
            .then(data => {
                setSnackbarMessage('Organization user registered successfully!');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                reset();
            })
            .catch(error => {
                setSnackbarMessage(error.message || 'Failed to register organization user.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            });
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const password = watch("password");

    return (
        <RequireAuth>
            <Container>
                <Typography variant="h4" gutterBottom>
                    Register Organization User
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                        label="Username"
                        {...register('user_name', { required: true })}
                        fullWidth
                        margin="normal"
                        error={!!errors.user_name}
                        helperText={errors.user_name ? 'Username is required' : ''}
                    />
                    <TextField
                        label="Email"
                        {...register('email', { required: true })}
                        fullWidth
                        margin="normal"
                        error={!!errors.email}
                        helperText={errors.email ? 'Email is required' : ''}
                    />
                    <TextField
                        label="Phone"
                        {...register('phone', { required: true })}
                        fullWidth
                        margin="normal"
                        error={!!errors.phone}
                        helperText={errors.phone ? 'Phone is required' : ''}
                    />
                    <FormControl fullWidth margin="normal" error={!!errors.position}>
                        <InputLabel>Position</InputLabel>
                        <Select
                            label="Position"
                            value={position}
                            {...register('position', { required: true })}
                            onChange={(e) => setPosition(e.target.value)}
                        >
                            {positionOptions.map((option) => (
                                <MenuItem key={option} value={option}>{option}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {position === 'Other' && (
                        <TextField
                            label="Custom Position"
                            value={customPosition}
                            onChange={(e) => setCustomPosition(e.target.value)}
                            fullWidth
                            margin="normal"
                            error={!!errors.customPosition}
                            helperText={errors.customPosition ? 'Custom Position is required' : ''}
                        />
                    )}
                    <TextField
                        label="Employer"
                        {...register('employer', { required: true })}
                        fullWidth
                        margin="normal"
                        error={!!errors.employer}
                        helperText={errors.employer ? 'Employer is required' : ''}
                    />
                    <TextField
                        label="Password"
                        type="password"
                        {...register('password', { required: true, minLength: 8 })}
                        fullWidth
                        margin="normal"
                        error={!!errors.password}
                        helperText={errors.password ? 'Password must be at least 8 characters long' : ''}
                    />
                    <TextField
                        label="Confirm Password"
                        type="password"
                        {...register('confirmPassword', {
                            required: true,
                            validate: value => value === password || "Passwords do not match"
                        })}
                        fullWidth
                        margin="normal"
                        error={!!errors.confirmPassword}
                        helperText={errors.confirmPassword ? errors.confirmPassword.message : ''}
                    />
                    <Button type="submit" variant="contained" color="primary">
                        Register
                    </Button>
                </form>
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Container>
        </RequireAuth>
    );
};

export default RegisterOrganizationUser;
