import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const RequireAuth = ({ children }) => {
    const { isAuthenticated, logout } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const verifyToken = async () => {
            const token = localStorage.getItem('access_token');
            if (!token) {
                setIsLoading(false);
                logout();
                return;
            }

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/token/validate`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (!response.ok) {
                    setIsTokenExpired(true);
                }
            } catch (error) {
                console.error('Error verifying token:', error);
                setIsTokenExpired(true);
            } finally {
                setIsLoading(false);
            }
        };

        verifyToken();
    }, [logout]);

    const handleDialogClose = () => {
        localStorage.removeItem('access_token');
        logout();
        navigate('/login');
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isTokenExpired) {
        return (
            <Dialog open={isTokenExpired} onClose={handleDialogClose}>
                <DialogTitle>Session Expired</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Your session has expired. Please log in again to continue.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    if (!isAuthenticated) {
        return navigate('/login');
    }

    return children;
};

export default RequireAuth;
