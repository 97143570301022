import React from 'react';
import { Box } from '@mui/material';
import ChapterList from '../components/ChapterList';
import RequireAuth from '../components/RequireAuth';

const Home = ({ api }) => {
    return (
        <RequireAuth>
            <Box sx={{ marginTop: "10px" }}>
                <ChapterList api={api} />
            </Box>
        </RequireAuth>
    );
}

export default Home;
