import React from 'react';
import { Container, Box, Typography, Divider } from '@mui/material';
import ChangeEmail from '../components/ChangeEmail';
import ChangePassword from '../components/ChangePassword';
import SubscriptionStatus from '../components/SubscriptionStatus';
import DeleteAccount from '../components/DeleteAccount';
import ManageOrganizationUsers from "./ManageOrganizationUsers"
import RequireAuth from '../components/RequireAuth';

const AccountSettings = ({ api }) => {
    const user_type = localStorage.getItem('user_type');
    return (
        <RequireAuth>
            <Container>
                <Box sx={{ marginTop: '20px' }}>
                    <Typography variant="h4" gutterBottom>
                        Account Settings
                    </Typography>
                    <Divider sx={{ marginBottom: '20px' }} />
                    <ChangeEmail api={api} />
                    <Divider sx={{ marginY: '20px' }} />
                    <ChangePassword api={api} />
                    <Divider sx={{ marginY: '20px' }} />
                    <SubscriptionStatus />
                    {user_type !== "organization_user" && (
                        <>
                            <Divider sx={{ marginY: '20px' }} />
                            <DeleteAccount api={api} />
                        </>
                    )}
                    {user_type === 'organization' && (
                        <>
                            <Divider sx={{ marginY: '20px' }} />
                            <ManageOrganizationUsers api={api} />
                        </>
                    )}
                </Box>
            </Container>
        </RequireAuth>
    );
};

export default AccountSettings;
