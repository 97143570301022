import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, Snackbar, Typography, Box, Select, MenuItem, FormControl, InputLabel, IconButton } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/system';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledForm = styled('form')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
}));

const ReferencesManager = ({ api }) => {
    const [references, setReferences] = useState([]);
    const [selectedReferenceId, setSelectedReferenceId] = useState('');
    const [description, setDescription] = useState('');
    const [file, setFile] = useState(null);
    const [filePath, setFilePath] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        const fetchReferences = async () => {
            try {
                const response = await fetch(`${api}/references`);
                const data = await response.json();
                setReferences(data);
            } catch (error) {
                setSnackbarMessage(`Error fetching references: ${error.message}`);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            }
        };

        fetchReferences();
    }, [api]);

    const handleReferenceChange = async (event) => {
        const referenceId = event.target.value;
        setSelectedReferenceId(referenceId);

        try {
            const response = await fetch(`${api}/reference/${referenceId}`);
            if (response.ok) {
                const data = await response.json();
                setDescription(data.description);
                setFilePath(data.file_path);
            } else {
                setSnackbarMessage('Failed to fetch reference details.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            }
        } catch (error) {
            setSnackbarMessage(`Error: ${error.message}`);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('description', description);
        if (file) {
            formData.append('file', file);
        }

        try {
            const response = await fetch(`${api}/reference/${selectedReferenceId}/update`, {
                method: 'PUT',
                body: formData,
            });

            if (response.ok) {
                setSnackbarMessage('Reference updated successfully!');
                setSnackbarSeverity('success');
                const data = await response.json();
                setFilePath(data.file_path);
            } else {
                const data = await response.json();
                setSnackbarMessage(`Failed to update reference: ${data.detail}`);
                setSnackbarSeverity('error');
            }
        } catch (error) {
            setSnackbarMessage(`Error: ${error.message}`);
            setSnackbarSeverity('error');
        } finally {
            setSnackbarOpen(true);
        }
    };

    const handleDelete = async () => {
        try {
            const response = await fetch(`${api}/reference/${selectedReferenceId}/delete`, {
                method: 'DELETE',
            });

            if (response.ok) {
                setSnackbarMessage('Reference deleted successfully!');
                setSnackbarSeverity('success');
                setSelectedReferenceId('');
                setDescription('');
                setFile(null);
                setFilePath('');
                setReferences(references.filter(reference => reference.id !== selectedReferenceId));
            } else {
                const data = await response.json();
                setSnackbarMessage(`Failed to delete reference: ${data.detail}`);
                setSnackbarSeverity('error');
            }
        } catch (error) {
            setSnackbarMessage(`Error: ${error.message}`);
            setSnackbarSeverity('error');
        } finally {
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleOpenPdf = () => {
        window.open(`${api}/reference/${selectedReferenceId}/download`, '_blank');
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Manage References
            </Typography>
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel>Select Reference to Edit</InputLabel>
                <Select value={selectedReferenceId} onChange={handleReferenceChange}>
                    {references.map((reference) => (
                        <MenuItem key={reference.id} value={reference.id}>
                            {reference.description}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {selectedReferenceId && (
                <StyledForm onSubmit={handleSubmit}>
                    <TextField
                        label="Description"
                        value={description}
                        onChange={handleDescriptionChange}
                        fullWidth
                        margin="normal"
                    />
                    <Button
                        variant="contained"
                        component="label"
                    >
                        Upload New PDF (optional)
                        <input
                            type="file"
                            hidden
                            accept="application/pdf"
                            onChange={handleFileChange}
                        />
                    </Button>
                    {file && <Typography variant="body2">{file.name}</Typography>}
                    <Box mt={2} display="flex" justifyContent="space-between" width="100%">
                        <Button variant="contained" color="primary" type="submit">
                            Update Reference
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<DeleteIcon />}
                            onClick={handleDelete}
                        >
                            Delete Reference
                        </Button>
                    </Box>
                </StyledForm>
            )}
            {filePath && (
                <Box mt={4}>
                    <Typography variant="h6">Current PDF:</Typography>
                    <IconButton onClick={handleOpenPdf} color="primary">
                        <OpenInNewIcon />
                    </IconButton>
                </Box>
            )}
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default ReferencesManager;
