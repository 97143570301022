import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Container,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Button,
    Snackbar,
    Alert as MuiAlert,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Box,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import RequireAuth from '../components/RequireAuth';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ManageOrganizationUsers = ({ api }) => {
    const [users, setUsers] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [userIdToDelete, setUserIdToDelete] = useState(null);
    const navigate = useNavigate();

    const fetchUsers = async () => {
        const token = localStorage.getItem('access_token');
        try {
            const response = await fetch(`${api}/organization_users`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            setUsers(data);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, [api]);

    const handleDelete = async (userId) => {
        const token = localStorage.getItem('access_token');
        try {
            const response = await fetch(`${api}/organization_user/${userId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });

            if (response.ok) {
                setSnackbarMessage('User deleted successfully.');
                setSnackbarSeverity('success');
                fetchUsers();
            } else {
                setSnackbarMessage('Failed to delete user.');
                setSnackbarSeverity('error');
            }
        } catch (error) {
            setSnackbarMessage('Failed to delete user.');
            setSnackbarSeverity('error');
        }
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleCreateNewUser = () => {
        navigate('/register/organization_user');
    };

    const handleDialogOpen = (userId) => {
        setUserIdToDelete(userId);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleDialogConfirmDelete = () => {
        handleDelete(userIdToDelete);
        handleDialogClose();
    };

    return (
        <RequireAuth>
            <Container>
                <Typography variant="h4" gutterBottom>
                    Manage Organization Users
                </Typography>
                <Button variant="contained" color="primary" onClick={handleCreateNewUser} sx={{ mb: 2 }}>
                    Create New User
                </Button>
                {users.length === 0 ? (
                    <Box textAlign="center" sx={{ mt: 4 }}>
                        <Typography variant="h6">
                            You have not created any users.
                        </Typography>
                    </Box>
                ) : (
                    <List>
                        {users.map((user) => (
                            <ListItem key={user.id}>
                                <ListItemText
                                    primary={user.user_name}
                                    secondary={`Email: ${user.email}, Phone: ${user.phone}`}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="delete" onClick={() => handleDialogOpen(user.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                )}
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
                <Dialog open={dialogOpen} onClose={handleDialogClose}>
                    <DialogTitle>Confirm Delete</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this user?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleDialogConfirmDelete} color="secondary">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </RequireAuth>
    );
};

export default ManageOrganizationUsers;
