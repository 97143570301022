import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Box, Grid, Paper, Button, MenuItem, Select, Snackbar, Alert as MuiAlert, FormControl, InputLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DateTime } from 'luxon';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const languageOptions = ["rw", "fr", "en"];
const statusOptions = ["Active", "Repealed", "Replaced/Amended"];

const UpdateDeleteChapter = ({ api }) => {
    const [chapters, setChapters] = useState([]);
    const [selectedChapter, setSelectedChapter] = useState(null);
    const [chapterName, setChapterName] = useState('');
    const [references, setReferences] = useState([]);
    const [translations, setTranslations] = useState([{ id: null, language_code: '', text: '', desc: '' }]);
    const [sections, setSections] = useState([
        {
            id: null,
            name: '',
            translations: [{ id: null, language_code: '', text: '', desc: '' }],
            articles_lst: [
                {
                    id: null,
                    title: '',
                    effective_date: DateTime.now().toISODate(),
                    status: 'Active',
                    reference_id: '',
                    translations: [{ id: null, language_code: '', text: '', desc: '' }]
                }
            ]
        }
    ]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [confirmDialogData, setConfirmDialogData] = useState(null);
    const [missingTranslationsDialogOpen, setMissingTranslationsDialogOpen] = useState(false);
    const [missingTranslationsMessage, setMissingTranslationsMessage] = useState('');

    useEffect(() => {
        fetchChapters();
        fetchReferences();
    }, [api]);

    const fetchChapters = async () => {
        try {
            const response = await fetch(`${api}/chapters/all`);
            const data = await response.json();
            setChapters(Array.isArray(data) ? data : []);
        } catch (error) {
            console.error('Error fetching chapters:', error);
        }
    };

    const fetchReferences = async () => {
        try {
            const response = await fetch(`${api}/references`);
            const data = await response.json();
            setReferences(data);
        } catch (error) {
            setSnackbarMessage(`Error fetching references: ${error.message}`);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const fetchChapterData = async (chapterId) => {
        try {
            const response = await fetch(`${api}/chapter/${chapterId}/get`);
            const data = await response.json();

            const structuredSections = data.sections_lst.map(section => ({
                ...section,
                translations: section.translations || [{ id: null, language_code: '', text: '', desc: '' }],
                articles_lst: section.articles_lst.map(article => ({
                    ...article,
                    effective_date: DateTime.fromISO(article.effective_date).toISODate(),
                    translations: article.translations || [{ id: null, language_code: '', text: '', desc: '' }]
                }))
            }));


            setChapterName(data.name);
            setTranslations(data.translations || [{ id: null, language_code: '', text: '', desc: '' }]);
            setSections(structuredSections);
            setSelectedChapter(chapterId);

            checkMissingTranslations(data.name, structuredSections, data.translations);
        } catch (error) {
            console.error('Error fetching chapter data:', error);
        }
    };

    const checkMissingTranslations = (chapterName, sections, chapterTranslations) => {
        const missingTranslations = [];
        const checkTranslations = (translations, itemName) => {
            const languages = translations.map(t => t.language_code);
            languageOptions.forEach(lang => {
                if (!languages.includes(lang)) {
                    missingTranslations.push(`${itemName} is missing a ${lang} translation`);
                }
            });
        };

        checkTranslations(chapterTranslations, `Chapter: ${chapterName}`);
        sections.forEach((section, sectionIndex) => {
            checkTranslations(section.translations, `Section ${sectionIndex + 1}: ${section.name}`);
            section.articles_lst.forEach((article, articleIndex) => {
                checkTranslations(article.translations, `Article ${articleIndex + 1}: ${article.title}`);
            });
        });
        if (missingTranslations.length > 0) {
            setMissingTranslationsMessage(missingTranslations.join('\n'));
            setMissingTranslationsDialogOpen(true);
        }
    };


    const handleChapterSelect = (event) => {
        const chapterId = event.target.value;
        fetchChapterData(chapterId);
    };

    const handleChapterNameChange = (e) => setChapterName(e.target.value);
    const handleTranslationChange = (index, key, value) => {
        const newTranslations = [...translations];
        newTranslations[index][key] = value;
        setTranslations(newTranslations);
    };

    const addTranslation = () => {
        setTranslations([...translations, { id: null, language_code: '', text: '', desc: '' }]);
    };

    const deleteTranslation = (index) => {
        setTranslations(translations.filter((_, i) => i !== index));
    };

    const handleSectionChange = (sectionIndex, key, value) => {
        const newSections = [...sections];
        newSections[sectionIndex][key] = value;
        setSections(newSections);
    };

    const handleSectionTranslationChange = (sectionIndex, index, key, value) => {
        const newSections = [...sections];
        newSections[sectionIndex].translations[index][key] = value;
        setSections(newSections);
    };

    const addSectionTranslation = (sectionIndex) => {
        const newSections = [...sections];
        newSections[sectionIndex].translations.push({ id: null, language_code: '', text: '', desc: '' });
        setSections(newSections);
    };

    const deleteSectionTranslation = (sectionIndex, index) => {
        const newSections = [...sections];
        newSections[sectionIndex].translations = newSections[sectionIndex].translations.filter((_, i) => i !== index);
        setSections(newSections);
    };

    const handleArticleChange = (sectionIndex, articleIndex, key, value) => {
        const newSections = [...sections];
        newSections[sectionIndex].articles_lst[articleIndex][key] = value;
        setSections(newSections);
    };

    const handleArticleDateChange = (sectionIndex, articleIndex, date) => {
        const newSections = [...sections];
        newSections[sectionIndex].articles_lst[articleIndex].effective_date = DateTime.fromJSDate(date).toISODate();
        setSections(newSections);
    };

    const handleArticleTranslationChange = (sectionIndex, articleIndex, index, key, value) => {
        const newSections = [...sections];
        newSections[sectionIndex].articles_lst[articleIndex].translations[index][key] = value;
        setSections(newSections);
    };

    const addArticleTranslation = (sectionIndex, articleIndex) => {
        const newSections = [...sections];
        newSections[sectionIndex].articles_lst[articleIndex].translations.push({ id: null, language_code: '', text: '', desc: '' });
        setSections(newSections);
    };

    const deleteArticleTranslation = (sectionIndex, articleIndex, index) => {
        const newSections = [...sections];
        newSections[sectionIndex].articles_lst[articleIndex].translations = newSections[sectionIndex].articles_lst[articleIndex].translations.filter((_, i) => i !== index);
        setSections(newSections);
    };

    const addSection = () => {
        setSections([...sections, {
            id: null,
            name: '',
            translations: [{ id: null, language_code: '', text: '', desc: '' }],
            articles_lst: [
                {
                    id: null,
                    title: '',
                    effective_date: DateTime.now().toISODate(),
                    status: 'Active',
                    reference_id: '',
                    translations: [{ id: null, language_code: '', text: '', desc: '' }]
                }
            ]
        }]);
    };

    const deleteSection = (sectionIndex) => {
        setConfirmDialogData({
            title: 'Delete Section',
            message: 'Are you sure you want to delete this section?',
            onConfirm: () => {
                setSections(sections.filter((_, index) => index !== sectionIndex));
                setConfirmDialogOpen(false);
            }
        });
        setConfirmDialogOpen(true);
    };

    const addArticle = (sectionIndex) => {
        const newSections = [...sections];
        newSections[sectionIndex].articles_lst.push({
            id: null,
            title: '',
            effective_date: DateTime.now().toISODate(),
            status: 'Active',
            reference_id: '',
            translations: [{ id: null, language_code: '', text: '', desc: '' }]
        });
        setSections(newSections);
    };

    const deleteArticle = (sectionIndex, articleIndex) => {
        setConfirmDialogData({
            title: 'Delete Article',
            message: 'Are you sure you want to delete this article?',
            onConfirm: () => {
                const newSections = [...sections];
                newSections[sectionIndex].articles_lst = newSections[sectionIndex].articles_lst.filter((_, i) => i !== articleIndex);
                setSections(newSections);
                setConfirmDialogOpen(false);
            }
        });
        setConfirmDialogOpen(true);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            id: selectedChapter,
            name: chapterName,
            translations,
            sections_lst: sections,
        };

        fetch(`${api}/chapter/${selectedChapter}/update`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => {
                setSnackbarMessage('Chapter updated successfully!');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                fetchChapters();
            })
            .catch(error => {
                setSnackbarMessage('Failed to update chapter.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            });
    };

    const handleDelete = () => {
        setConfirmDialogData({
            title: 'Delete Chapter',
            message: 'Are you sure you want to delete this chapter?',
            onConfirm: () => {
                fetch(`${api}/chapter/${selectedChapter}/del`, {
                    method: 'DELETE',
                    headers: { 'Content-Type': 'application/json' },
                })
                    .then(response => response.json())
                    .then(data => {
                        setSnackbarMessage('Chapter deleted successfully!');
                        setSnackbarSeverity('success');
                        setSnackbarOpen(true);
                        setChapterName('');
                        setTranslations([{ id: null, language_code: '', text: '', desc: '' }]);
                        setSections([{ id: null, name: '', translations: [{ id: null, language_code: '', text: '', desc: '' }], articles_lst: [{ id: null, title: '', translations: [{ id: null, language_code: '', text: '', desc: '' }] }] }]);
                        setSelectedChapter(null);
                        fetchChapters();
                        setConfirmDialogOpen(false);
                    })
                    .catch(error => {
                        setSnackbarMessage('Failed to delete chapter.');
                        setSnackbarSeverity('error')
                        setSnackbarOpen(true);
                        setConfirmDialogOpen(false);
                    });
            }
        });
        setConfirmDialogOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <Container sx={{ margin: "10px" }}>
            <Typography variant="h4" gutterBottom>
                Update or Delete Chapter
            </Typography>
            <Select
                value={selectedChapter || ''}
                onChange={handleChapterSelect}
                fullWidth
                displayEmpty
            >
                <MenuItem value="" disabled>Select a chapter to edit</MenuItem>
                {Array.isArray(chapters) && chapters.map((chapter) => (
                    <MenuItem key={chapter.id} value={chapter.id}>{chapter.name}</MenuItem>
                ))}
            </Select>
            {selectedChapter && (
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Chapter Name"
                        value={chapterName}
                        onChange={handleChapterNameChange}
                        fullWidth
                        margin="normal"
                    />
                    <Box mt={3}>
                        <Typography variant="h6">Translations</Typography>
                        {translations.map((translation, index) => (
                            <Grid container spacing={2} key={index}>
                                <Grid item xs={4}>
                                    <Select
                                        label="Language Code"
                                        value={translation.language_code}
                                        onChange={(e) => handleTranslationChange(index, 'language_code', e.target.value)}
                                        fullWidth
                                    >
                                        {languageOptions.map((option) => (
                                            <MenuItem key={option} value={option}>{option}</MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Text"
                                        value={translation.text}
                                        onChange={(e) => handleTranslationChange(index, 'text', e.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button onClick={() => {
                                        setConfirmDialogData({
                                            title: 'Delete Translation',
                                            message: 'Are you sure you want to delete this translation?',
                                            onConfirm: () => {
                                                deleteTranslation(index);
                                                setConfirmDialogOpen(false);
                                            }
                                        });
                                        setConfirmDialogOpen(true);
                                    }}>
                                        Delete Translation
                                    </Button>
                                </Grid>
                            </Grid>
                        ))}
                        <Button onClick={addTranslation}>Add Translation</Button>
                    </Box>
                    <Box mt={3}>
                        <Typography variant="h6">Sections</Typography>
                        {sections.map((section, sectionIndex) => (
                            <Paper key={sectionIndex} variant="outlined" sx={{ mb: 2, p: 2 }}>
                                <TextField
                                    label="Section Name"
                                    value={section.name}
                                    onChange={(e) => handleSectionChange(sectionIndex, 'name', e.target.value)}
                                    fullWidth
                                    margin="normal"
                                />
                                <Button onClick={() => deleteSection(sectionIndex)} color="error" sx={{ mt: 2 }}>Delete Section</Button>
                                <Typography variant="h6" sx={{ mt: 3 }}>Section Translations</Typography>
                                {section.translations.map((translation, index) => (
                                    <Grid container spacing={2} key={index}>
                                        <Grid item xs={4}>
                                            <Select
                                                label="Language Code"
                                                value={translation.language_code}
                                                onChange={(e) => handleSectionTranslationChange(sectionIndex, index, 'language_code', e.target.value)}
                                                fullWidth
                                            >
                                                {languageOptions.map((option) => (
                                                    <MenuItem key={option} value={option}>{option}</MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                label="Text"
                                                value={translation.text}
                                                onChange={(e) => handleSectionTranslationChange(sectionIndex, index, 'text', e.target.value)}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button onClick={() => {
                                                setConfirmDialogData({
                                                    title: 'Delete Section Translation',
                                                    message: 'Are you sure you want to delete this section translation?',
                                                    onConfirm: () => {
                                                        deleteSectionTranslation(sectionIndex, index);
                                                        setConfirmDialogOpen(false);
                                                    }
                                                });
                                                setConfirmDialogOpen(true);
                                            }}>
                                                Delete Section Translation
                                            </Button>
                                        </Grid>
                                    </Grid>
                                ))}
                                <Button onClick={() => addSectionTranslation(sectionIndex)}>Add Section Translation</Button>
                                <Typography variant="h6" sx={{ mt: 3 }}>Articles</Typography>
                                {section.articles_lst.map((article, articleIndex) => (
                                    <Paper key={articleIndex} variant="outlined" sx={{ mb: 2, p: 2 }}>
                                        <TextField
                                            label="Article Title"
                                            value={article.title}
                                            onChange={(e) => handleArticleChange(sectionIndex, articleIndex, 'title', e.target.value)}
                                            fullWidth
                                            margin="normal"
                                        />
                                        <DatePicker
                                            selected={DateTime.fromISO(article.effective_date).toJSDate()}
                                            onChange={(date) => handleArticleDateChange(sectionIndex, articleIndex, date)}
                                            dateFormat="MMMM d, yyyy"
                                            customInput={
                                                <TextField
                                                    label="Effective Date"
                                                    fullWidth
                                                    margin="normal"
                                                />
                                            }
                                        />
                                        <FormControl fullWidth margin="normal">
                                            <InputLabel>Status</InputLabel>
                                            <Select
                                                value={article.status}
                                                onChange={(e) => handleArticleChange(sectionIndex, articleIndex, 'status', e.target.value)}
                                            >
                                                {statusOptions.map((option) => (
                                                    <MenuItem key={option} value={option}>{option}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <FormControl fullWidth margin="normal">
                                            <InputLabel>Reference</InputLabel>
                                            <Select
                                                value={article.reference_id}
                                                onChange={(e) => handleArticleChange(sectionIndex, articleIndex, 'reference_id', e.target.value)}
                                                required
                                            >
                                                {references.map((reference) => (
                                                    <MenuItem key={reference.id} value={reference.id}>
                                                        {reference.description}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <Typography variant="h6" sx={{ mt: 3 }}>Article Translations</Typography>
                                        {article.translations.map((translation, index) => (
                                            <Grid container spacing={2} key={index}>
                                                <Grid item xs={4}>
                                                    <Select
                                                        label="Language Code"
                                                        value={translation.language_code}
                                                        onChange={(e) => handleArticleTranslationChange(sectionIndex, articleIndex, index, 'language_code', e.target.value)}
                                                        fullWidth
                                                    >
                                                        {languageOptions.map((option) => (
                                                            <MenuItem key={option} value={option}>{option}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        label="Text"
                                                        value={translation.text}
                                                        onChange={(e) => handleArticleTranslationChange(sectionIndex, articleIndex, index, 'text', e.target.value)}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        label="Description"
                                                        value={translation.desc}
                                                        multiline
                                                        onChange={(e) => handleArticleTranslationChange(sectionIndex, articleIndex, index, 'desc', e.target.value)}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button onClick={() => {
                                                        setConfirmDialogData({
                                                            title: 'Delete Article Translation',
                                                            message: 'Are you sure you want to delete this article translation?',
                                                            onConfirm: () => {
                                                                deleteArticleTranslation(sectionIndex, articleIndex, index);
                                                                setConfirmDialogOpen(false);
                                                            }
                                                        });
                                                        setConfirmDialogOpen(true);
                                                    }}>
                                                        Delete Article Translation
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        ))}
                                        <Button onClick={() => addArticleTranslation(sectionIndex, articleIndex)}>Add Article Translation</Button>
                                        <Button onClick={() => deleteArticle(sectionIndex, articleIndex)} color="error" sx={{ mt: 2 }}>Delete Article</Button>
                                    </Paper>
                                ))}
                                <Button onClick={() => addArticle(sectionIndex)}>Add Article</Button>
                            </Paper>
                        ))}
                        <Button onClick={addSection}>Add Section</Button>
                    </Box>
                    <Box mt={3} sx={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: 1, padding: '10px', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)' }}>
                        <Button variant="contained" color="primary" type="submit">
                            Update Chapter
                        </Button>
                        <Button variant="contained" color="secondary" onClick={handleDelete} sx={{ ml: 2 }}>
                            Delete Chapter
                        </Button>
                    </Box>
                </form>
            )}
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            {/* Confirmation Dialog */}
            <Dialog
                open={confirmDialogOpen}
                onClose={() => setConfirmDialogOpen(false)}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
            >
                <DialogTitle id="confirm-dialog-title">{confirmDialogData?.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-dialog-description">
                        {confirmDialogData?.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={confirmDialogData?.onConfirm} color="secondary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={missingTranslationsDialogOpen}
                onClose={() => setMissingTranslationsDialogOpen(false)}
                aria-labelledby="missing-translations-dialog-title"
                aria-describedby="missing-translations-dialog-description"
            >
                <DialogTitle id="missing-translations-dialog-title">Missing Translations</DialogTitle>
                <DialogContent>
                    <DialogContentText id="missing-translations-dialog-description">
                        {missingTranslationsMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setMissingTranslationsDialogOpen(false)} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default UpdateDeleteChapter;
