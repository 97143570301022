import React from 'react';
import { useForm } from 'react-hook-form';
import { TextField, Button, Box, Typography, Snackbar, Alert as MuiAlert } from '@mui/material';

const ChangePassword = ({ api }) => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const [snackbarSeverity, setSnackbarSeverity] = React.useState('success');

    const onSubmit = (data) => {
        const token = localStorage.getItem('access_token');

        fetch(`${api}/user/update_password`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                current_password: data.currentPassword,
                new_password: data.newPassword
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to change password');
                }
                return response.json();
            })
            .then(data => {
                setSnackbarMessage('Password changed successfully!');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                reset();
            })
            .catch(error => {
                setSnackbarMessage('Failed to change password.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            });
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <Box>
            <Typography variant="h5" gutterBottom>
                Change Password
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                    label="Current Password"
                    type="password"
                    {...register('currentPassword', { required: true })}
                    fullWidth
                    margin="normal"
                    error={!!errors.currentPassword}
                    helperText={errors.currentPassword ? 'Current password is required' : ''}
                />
                <TextField
                    label="New Password"
                    type="password"
                    {...register('newPassword', { required: true })}
                    fullWidth
                    margin="normal"
                    error={!!errors.newPassword}
                    helperText={errors.newPassword ? 'New password is required' : ''}
                />
                <Button type="submit" variant="contained" color="primary">
                    Change Password
                </Button>
            </form>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </Box>
    );
};

export default ChangePassword;
