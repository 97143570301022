import React, { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Container, Grid, IconButton, Drawer, Box, Hidden } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar';
import QuoteSlideshow from '../components/QuoteSlideshow';

const Layout = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState('en');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const location = useLocation();
    const api = process.env.REACT_APP_API_URL;

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    const handleDrawerClick = (event) => {
        event.stopPropagation();
    };

    const showSidebar = location.pathname === '/' || location.pathname === '/favorites';

    return (
        <>
            <Header />
            <Container sx={{ minHeight: '85vh' }} maxWidth>
                <Grid container spacing={2} justifyContent="center">
                    {showSidebar && (
                        <>
                            <Hidden mdUp>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    onClick={toggleDrawer}
                                    sx={{ position: 'fixed', top: 70, right: 16, zIndex: 1201 }}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Hidden>
                            <Drawer
                                anchor="left"
                                open={isDrawerOpen}
                                onClose={toggleDrawer}
                                ModalProps={{
                                    keepMounted: true,
                                }}
                            >
                                <Box
                                    sx={{ width: 250 }}
                                    role="presentation"
                                    onClick={handleDrawerClick}
                                >
                                    <Sidebar
                                        searchQuery={searchQuery}
                                        setSearchQuery={setSearchQuery}
                                        selectedLanguage={selectedLanguage}
                                        selectedStatus={selectedStatus}
                                        setSelectedStatus={setSelectedStatus}
                                        setSelectedLanguage={setSelectedLanguage}
                                        selectedYear={selectedYear}
                                        setSelectedYear={setSelectedYear}
                                    />
                                </Box>
                            </Drawer>
                            <Hidden mdDown>
                                <Grid item md={3}>
                                    <Box sx={{ position: 'sticky', top: 80 }}>
                                        <Sidebar
                                            searchQuery={searchQuery}
                                            setSearchQuery={setSearchQuery}
                                            selectedLanguage={selectedLanguage}
                                            selectedStatus={selectedStatus}
                                            setSelectedStatus={setSelectedStatus}
                                            setSelectedLanguage={setSelectedLanguage}
                                            selectedYear={selectedYear}
                                            setSelectedYear={setSelectedYear}
                                        />
                                    </Box>
                                </Grid>
                            </Hidden>
                        </>
                    )}
                    <Grid item xs={12} md={showSidebar ? 6 : 12}>
                        <Outlet context={{ searchQuery, selectedLanguage, selectedStatus, selectedYear }} />
                    </Grid>
                    {showSidebar && (
                        <Hidden mdDown>
                            <Grid item md={3}>
                                <Box sx={{ position: 'sticky', top: "40%", maxHeight: 150 }}>
                                    <QuoteSlideshow api={api} />
                                </Box>
                            </Grid>
                        </Hidden>
                    )}
                </Grid>
            </Container>
            <Footer />
        </>
    );
};

export default Layout;
