import React, { useState, useEffect } from 'react';
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Checkbox, Button, Snackbar, Paper, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ManageQuotes = ({ api }) => {
    const [quotes, setQuotes] = useState([]);
    const [newQuote, setNewQuote] = useState({ id: 0, text: '', visible: true });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        fetchQuotes();
    }, [api]);

    const fetchQuotes = async () => {
        try {
            const response = await fetch(`${api}/quotes`);
            const data = await response.json();
            setQuotes(data);
        } catch (error) {
            console.error('Error fetching quotes:', error);
        }
    };

    const handleQuoteChange = (index, field, value) => {
        const updatedQuotes = [...quotes];
        updatedQuotes[index][field] = value;
        setQuotes(updatedQuotes);
    };

    const handleUpdateQuote = async (quote) => {
        try {
            const response = await fetch(`${api}/quotes/update`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(quote)
            });
            if (response.ok) {
                setSnackbarMessage('Quote updated successfully!');
                setSnackbarSeverity('success');
                fetchQuotes();
            } else {
                throw new Error('Failed to update quote.');
            }
        } catch (error) {
            console.error('Error updating quote:', error);
            setSnackbarMessage('Failed to update quote.');
            setSnackbarSeverity('error');
        } finally {
            setSnackbarOpen(true);
        }
    };

    const handleDeleteQuote = async (quoteId) => {
        try {
            const response = await fetch(`${api}/quotes/delete/${quoteId}`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' }
            });
            if (response.ok) {
                setSnackbarMessage('Quote deleted successfully!');
                setSnackbarSeverity('success');
                fetchQuotes();
            } else {
                throw new Error('Failed to delete quote.');
            }
        } catch (error) {
            console.error('Error deleting quote:', error);
            setSnackbarMessage('Failed to delete quote.');
            setSnackbarSeverity('error');
        } finally {
            setSnackbarOpen(true);
        }
    };

    const handleAddQuote = async () => {
        try {
            const response = await fetch(`${api}/quotes/add`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(newQuote)
            });
            if (response.ok) {
                setSnackbarMessage('Quote added successfully!');
                setSnackbarSeverity('success');
                setNewQuote({ text: '', visible: true });
                fetchQuotes();
            } else {
                throw new Error('Failed to add quote.');
            }
        } catch (error) {
            console.error('Error adding quote:', error);
            setSnackbarMessage('Failed to add quote.');
            setSnackbarSeverity('error');
        } finally {
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Manage Quotes
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Quote</TableCell>
                            <TableCell>Visible</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {quotes.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={3}>
                                    <Typography variant="h6" align="center">
                                        No quotes available.
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ) : (
                            quotes.map((quote, index) => (
                                <TableRow key={quote.id}>
                                    <TableCell>
                                        <TextField
                                            value={quote.text}
                                            onChange={(e) => handleQuoteChange(index, 'text', e.target.value)}
                                            fullWidth
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox
                                            checked={quote.visible}
                                            onChange={(e) => handleQuoteChange(index, 'visible', e.target.checked)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleUpdateQuote(quote)}
                                            sx={{ mr: 1 }}
                                        >
                                            Update
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleDeleteQuote(quote.id)}
                                        >
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                        <TableRow>
                            <TableCell>
                                <TextField
                                    value={newQuote.text}
                                    onChange={(e) => setNewQuote({ ...newQuote, text: e.target.value })}
                                    fullWidth
                                    placeholder="New quote"
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={newQuote.visible}
                                    onChange={(e) => setNewQuote({ ...newQuote, visible: e.target.checked })}
                                />
                            </TableCell>
                            <TableCell>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleAddQuote}
                                >
                                    Add
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default ManageQuotes;
