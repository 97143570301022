import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

const Footer = () => {
    return (
        <AppBar position="bottom" color="primary" sx={{ top: 'auto', bottom: 0 }}>
            <Container maxWidth="xl">
                <Toolbar>
                    <Typography variant="body1" color="inherit" align="center" sx={{ flexGrow: 1 }}>
                        &copy; {new Date().getFullYear()} KaizenMind. All rights reserved.
                    </Typography>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Footer;
